function browserCheck(){
    var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;  // Opera 8.0+
    var isFirefox = typeof InstallTrigger !== 'undefined';        // Firefox 1.0+
    var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || safari.pushNotification);// Safari 3.0+ "[object HTMLElementConstructor]" 
    var isIE = /*@cc_on!@*/false || !!document.documentMode;    // Internet Explorer 6-11
    var isEdge = !isIE && !!window.StyleMedia;    // Edge 20+
    var isChrome = !!window.chrome && !!window.chrome.webstore;    // Chrome 1+
    var isBlink = (isChrome || isOpera) && !!window.CSS;    // Blink engine detection
    if (isOpera){}
    if (isFirefox){}
    if (isSafari){}
    if (isIE){ $("html").addClass("ie")}
    if (isEdge){}
    if (isChrome){$("html").addClass("chrome")}
    if (isBlink){}
    //Check Device
    var isTouch = ('ontouchstart' in document.documentElement);
    //Check Device //All Touch Devices
    if ( isTouch ) { $('html').addClass('touch')}
    else { $('html').addClass('no-touch') };
}
function cookieMelding(){
  var date = new Date();
  var minutes = 60;
  date.setTime(date.getTime() + (minutes * 60 * 1000));


    if ($.cookie('popup-test3') != '1'){
      $("body").addClass("cookiemelding-active");
    }
    $(".close").click(function(){
      $("body").removeClass("cookiemelding-active");
        jQuery.cookie('popup-test3', '1'
        , { expires: 365 }
      ); 
      return false;
    });
}
function adminMenu(){
  var admin = $("#admin-menu");
  var header = $("#header");
  if (admin.length > 0){
    var h = admin.outerHeight();
    header.css("top", h);
  }
}

function menuDesktop(){
  if ($(window).width() > 801){
    $( "#menu" ).mouseover(function() {
      $('#menu').addClass('open');
    });
    $( "#menu" ).mouseleave(function() {
      $('#menu').removeClass('open');
    });
  }
}
function menuMobiel(){
  if ($(window).width() <= 800){
    $( "#menu #menubutton" ).click(function(){
      $('#menu').toggleClass('open');
    });
  }
}
function hoogtes(){
  $("form .webform-component--links > div, form .webform-component-textarea > div > textarea").matchHeight();
  $(".view-overzichtpagina .views-field-body, .view-andere .views-field-body, .view-nieuws-overzicht .views-field-body").matchHeight();
  $(".view-gerelateerd-nieuws .views-field-body").matchHeight();
  $(".view-overzichtpagina .views-field-title, .view-andere .views-field-title").matchHeight();
  $(".view-overzichtpagina .views-field-field-icoon, .view-andere .views-field-field-icoon").matchHeight();
  $(".view-nieuws-overzicht .titlewrap, .view-gerelateerd-nieuws .titlewrap").matchHeight();
  $(".view-overzichtpagina .row-inner, .view-andere .row-inner, .view-nieuws-overzicht .row-inner").matchHeight();
  $(".view-gerelateerd-nieuws .row-inner").matchHeight();
  $("#block-views-personen-block .views-field-field-foto").matchHeight();
}

// checken of de css van de map veranderd.
function watch(){
    // some element to monitor
    var el = $(".slick-track");

    // hook up the watcher
    el.watch({
        // specify CSS styles or attribute names to monitor
        properties: "left,cursor,transform,transform-origin",
    
        // callback function when a change is detected
        callback: function(data, i) {
            var propChanged = data.props[i];
            var newValue = data.vals[i];
    
            var el = this;
            var el$ = $(this);
    
            // do what you need based on changes
            // or do your own checks
            slickcurrent();
        }
    });
}
function wrapRij(){
  var overzichten = $(".page-views.page-nieuws #content .view .view-content .item");
    if ($(".rij-wrap").length > 0){
      overzichten.unwrap();
    }
    if ($(window).width() > 769) {
      for(var i = 0; i < overzichten.length; i+=3) {
        overzichten.slice(i, i+3).wrapAll("<div class='rij-wrap'></div>");
      }
    }
    if (($(window).width() < 769) && ($(window).width() > 480)) {
      for(var i = 0; i < overzichten.length; i+=2) {
        overzichten.slice(i, i+2).wrapAll("<div class='rij-wrap'></div>");
      }
    }
}

function https(){
  $("iframe").each(function() {
    $(this).attr("src", $(this).attr("src").replace("http://", "https://"));
  });
}
function youtube(){
  $("iframe[src*=youtube]").each(function() {
    $(this).wrap('<div class="youtube"><div class="inner"></div></div>');
  });
}
function touchHovers(elem){
    $(elem).each(function(){
        $(this).on("touchstart touchend", $.debounce(100, function(event) {
            $(this).toggleClass("hover");
            setTimeout(toucheinde($(this)), 100);
        }));
        function toucheinde(deze){
            $("body").on("touchstart", function(){
                    deze.removeClass("hover");
            });
        }
    });
}

function bezigMetInvullen(deze){
    if (deze.val().length === 0 ){
      deze.closest(".form-item").removeClass("invullen");
    }
    else{
      deze.closest(".form-item").addClass("invullen");
    }
}
function textareaLabel(){
  $("textarea, input:not([type='submit']):not([type='checkbox']):not([type='radio']), select").focus(function(){
    $(this).closest(".form-item").addClass("invullen");
  }).on( "focusout", function(){
    bezigMetInvullen($(this));
  }).change(function(){
    bezigMetInvullen($(this));
  });
  $("textarea, input:not([type='submit']):not([type='checkbox']):not([type='radio']), select").each(function(){
    bezigMetInvullen($(this));
  });
  $(".webform-client-form select").on( "selectmenuchange", function( event, ui ) {
        var id = event.toElement.id;
        var parent = event.target.id;
        if (id === "ui-id-1"){
          $("#" + parent).closest(".form-item").removeClass("invullen");
        }
        else{
          $("#" + parent).closest(".form-item").addClass("invullen");
        }
  });
  $(".view-filters select").on( "selectmenuchange", function( event, ui ) {
        var id = event.toElement.id;
        var parent = event.target.id;
        if (id === "ui-id-1"){
          $("#" + parent).closest(".views-exposed-widget").removeClass("invullen");
        }
        else{
          $("#" + parent).closest(".views-exposed-widget").addClass("invullen");
        }
  });
}
function headerLoggedIn(){
  var h = $("#admin-menu").outerHeight();
  $("#header").css("top", h);
}
function scrollCheck(){
  var $document = $(document),
      $element = $('html'),
      className = 'hasScrolled';
  $(window).bind("load scroll", function() {
    if ($document.scrollTop() >= 30) {
      $element.addClass(className);
    } else {
      $element.removeClass(className);
    }
  });
}
function filterCheck(){
  var deze = $(".view-filters").closest(".view");
  $( document ).ajaxStart(function() {
      deze.addClass("filter-laden");
  });
  $( document ).ajaxComplete(function(e) {
    
        console.log(e);
      deze.removeClass("filter-laden");

      selectmenuValueChecker();
      if ($("html.no-touchevents").length > 0){
        $("select").selectmenu();
      } 
      hoogtes();
      filterAutoSubmit();
  });
}
function filterAutoSubmit(){
  if ($("html.no-touchevents").length > 0){
    $(".view-filters select").on( "selectmenuchange", function() {
      $(this).closest("form").find("input[type='submit']").click();
    });
  }
  else {
    $(".view-filters select").change(function() {
      $(this).closest("form").find("input[type='submit']").click();
    });
  }
}
function selectmenuValueChecker(){
  $(".view-filters select").on( "selectmenucreate selectmenuchange", function( event, ui ) {
    var uiID = event.target.nextSibling.id
    var itemID = (event.target.selectedIndex);
    if (itemID == 0) {
      $(("#" + uiID)).attr("placeholder", true);
    } else {
      $(("#" + uiID)).attr("placeholder", false);
    }

  });
}
var $ =jQuery.noConflict();
// functies aanroepen
$(document).ready(function () {
  if ($(".view-cookiemelding").length > 0){
      cookieMelding();
  }
  browserCheck();
  menuMobiel();
  textareaLabel();
  hoogtes();
  scrollCheck();
  filterCheck();
  selectmenuValueChecker();
  filterAutoSubmit();
  //if ($("html.no-touchevents").length > 0){
    $("select").selectmenu();
  //}
  //https();
  //youtube();
  $(window).load(function() {
    headerLoggedIn();
    //adminMenu();
  });
  
  $("a").smoothScroll({
      speed: 800
  });

  //==================

  $(window).bind("load scroll", function() {

  });
  
  $(window).bind("load resize", function() {
    //wrapRij();
    //hoogtes();
    //menuKleur();

  //==================
  
  });
});